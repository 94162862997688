<template>
  <div class="applybank_box">
    <div class="bank_card">
      <img src="../../../assets/PayFee/add_none.png" alt="">
      <span>{{bankAcount?bankAcount:'暂无银行代扣账号'}}</span>
    </div>
    <van-button type="info" @click="applyAcount">申请银行代扣</van-button>
    <div class="notice">
      <p>目前仅支持一下银行储蓄卡：</p>
      <p>工商银行、中国银行、建设银行、邮政储蓄银行、农村商业银行</p>
      <p><span>*</span>申请代扣签约时间为：7：30-19：30</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      bankAcount: ''
    }
  },
  methods: {
    applyAcount () {
      if (!this.bankAcount) {
        this.$router.push('/ApplyBankAcount')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.applybank_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  height: 100vh;
  width: 100vw;
  .bank_card {
    position: relative;
    width: 100%;
    text-align: left;
    img {
      width: 100%;
    }
    span {
      position: absolute;
      top: 20px;
      left: 20px;
      color: rgb(230, 223, 223);
      font-size: 18px;
    }
  }
  .van-button {
    width: 90%;
    border-radius: 45px;
    margin: 18px 0;
    font-size: 20px;
  }
  .notice {
    text-align: left;
    p {
      margin: 0;
      line-height: 30px;
      span {
        color: red;
      }
    }
    p:nth-child(1) {
      color: rgb(158, 157, 157);
    }
  }
}
</style>